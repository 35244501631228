import { withStyles, Radio } from '@material-ui/core';




export const CustomRadio = withStyles({
  root: {
    color: '#333',
    '&$checked': {
      color: '#333',
    },
  },
  checked: {},
})(Radio);

